@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

* {
  box-sizing: border-box;
  font-family: "Spoqa Han Sans Neo", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
}

/* @font-face {
    font-family: "SourceSansProBold";
    src: url("./assets/font/SourceSansPro-Bold.ttf");
}

@font-face {
    font-family: "SourceSansProSemiBold";
    src: url("./assets/font/SourceSansPro-Bold.ttf");
}

@font-face {
    font-family: "SourceSansProRegular";
    src: url("./assets/font/SourceSansPro-Bold.ttf");
} */

body {
  margin: 0;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  height: 100%;
  background: rgb(247, 249, 250);
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

ol,
ul,
li {
  list-style: none;
  padding: 0;
}

input,
button {
  outline: none;
  background-color: transparent;
  color: #000000;
  border: none;
}

textarea {
  outline: none;
}

#root {
  height: calc(var(--vh) * 100);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

#root > div:not(#mobile-header, #navigation-bar) {
  flex: 100%;
}
